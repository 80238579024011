<template>
	<div>
		<header class="modal-card-head">
			<h4 class="modal-card-title ellipsis">
				<strong class="is-block">Contestação</strong>
				<span class="is-contest" v-if="criteria">{{ criteria.name }}</span>
			</h4>
			<b-button class="is-rounded is-outlined is-danger modal-card-title-button" v-if="!disabledForm && isAdmin" @click="endContest()">Encerrar</b-button>
		</header>
		<div ref="chat" class="modal-card-body modal-select chat-modal">
			<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
			<div v-if="criteria" class="chat-texts">
				<div v-for="contest in criteria.contests" :key="contest.id" class="text" :class="[contest.user_id == user.id ? 'sent' : '', contest.deleted ? 'deleted' : '']">
					<div class="profile-pic" :style="{ 'border-color': 'contest.user.permission.color' }">
						<img v-if="!contest.user" src="@/assets/images/user.jpg" title="Usuário Removido" />
						<img v-else :src="contest.user.avatar" :title="contest.user.full_name">
					</div>
					<div class="text-wrapper"> 
						<span class="username">{{ contest.user.full_name }}</span>
						<div class="text-content">
							<span v-if="contest.message">{{ contest.message }}</span>
							<a v-else :href="contest.url" target="_blank">
								<svg-icon class="icon icon-download is-small" :icon="getFileTextension(contest.file.toLowerCase())" />
							</a>
						</div>
						<span class="timestamp">
							<span v-if="contest.deleted" class="timestamp-action">
								Apagada em {{ contest.date }}
							</span>
							<span v-if="contest.edited" class="timestamp-action">
								<a @click="viewContestHistory(contest)">Editada em {{ contest.date }}</a>
							</span>
							{{ contest.date }} • {{ contest.hour }}
						</span>
						<div v-if="contest.user_id == user.id" class="text-actions" :class="{ single : !contest.message }">
							<button v-if="contest.message" title="Editar" @click="editMessage(contest)">
								<svg-icon class="icon" icon="edit-message" />
							</button>
							<button title="Apagar" @click="removeMessage(contest)">
								<svg-icon class="icon" icon="trash" />
							</button>
						</div>
					</div>
				</div>
			</div>
			<div v-if="criteria && criteria.contests.length == 0" class="notification is-info">
				Qual o <strong>motivo</strong> da sua contestação?
			</div>
		</div>
		<footer class="modal-card-foot">
			<form class="send-message" ref="formChat" @submit.prevent="sendMessage">
				<div class="message-text">
					<input type="text" v-model="form.message" placeholder="Mensagem..." :disabled="disabledForm">
					<label for="attachment">
						<svg-icon class="icon is-small" icon="attachment" />
						<input id="attachment" type="file" class="hidden" @change="fileUpload" accept=".txt, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .csv, .pdf, .png, .jpg, .jpeg" :disabled="disabledForm" />
					</label>
				</div>
				<b-button native-type="submit" class="is-rounded is-secondary is-outlined" :loading="loadingSend" :disabled="disabledForm">Enviar</b-button>
			</form>
		</footer>
	</div>
</template>

<script>
import Icon from '@/components/Icon'
import Message from '@/components/modals/Message'
import { mapState, mapMutations } from 'vuex'
import { errorToast } from '@/mixins/toast'
import { ModalProgrammatic as Modal } from 'buefy'
import Api from '@/services/api'

export default {
	components: {
		'svg-icon': Icon
	},
	props: {
		id: {
			type: [Number, String],
			required: true
		},
		setDone: {
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loadingSend: false,
			criteria: null,
			dealerRate: null,
			contestDone: false,
			form: {
				message: ''
			}
		}
	},
	computed: {
		...mapState('user', ['user', 'ratingCriteriaId']),
		disabledForm() {
			if (!this.dealerRate) {
				return true
			}

			if (['accepted'].includes(this.dealerRate.status.name)) {
				return true
			}

			if (this.contestDone) {
				return true
			}

			return false
		},
		isAdmin() {
			if (this.user && ['master', 'manager'].includes(this.user.role.name)) {
				return true
			}

			return false
		}
	},
	watch: {
		ratingCriteriaId(v) {
			if (v && v == this.id) {
				this.setRatingCriteriaId(null)
				this.findContestByCriteria()
			}
		}
	},
	methods: {
		...mapMutations('user', [
			'setRatingCriteriaId',
			'setCriteriaContest'
		]),
		updateChatScroll() {
			var chat = this.$refs.chat
			chat.scrollTop = chat.scrollHeight
		},
		getFileTextension(file) {
			var ext = file.split('.')[1]
			return ext
		},
		async fileUpload(event) {
			if (event.target.files.length > 0) {
				try {
					this.loadingSend = true
					const formData = new FormData()
					formData.append('rating_criteria_id', this.id)
					formData.append('file', event.target.files[0])

					const response = await Api.post('contest/store', formData)

					if (response.status === 201) {
						this.findContestByCriteria()
					}
				} catch (e) {
					console.log(e)
					errorToast('Ocorreu um <strong>erro</strong> ao salvar o arquivo')
				} finally {
					this.loadingSend = false
					this.$refs.formChat.reset()
				}
			}
		},
		async findContestByCriteria() {
			try {
				const response = await Api.get('contest/findByCriteriaRatingId/' + this.id)

				if (response.status === 200) {
					if (response.data.criteria.contests.length > 0) {
						this.contestDone = response.data.criteria.contests[0].done
					}
					this.dealerRate = response.data.dealerRate
					this.criteria = response.data.criteria
					this.updateChatScroll()

					this.setCriteriaContest(response.data.criteria)
				}
			} catch (e) {
				console.log(e)
				errorToast('Ocorreu um <strong>erro</strong> ao buscar as contestações')
			} finally {
				this.isOpening = false
			}
		},
		async endContest () {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: 'Atenção',
				message: '<span>Você realmente deseja <br><strong>encerrar</strong> esta contestação?</span> <small>Ela não poderá ser reaberta novamente.</small>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: 'Não',
				confirmText: 'Sim',
				onConfirm: async () => {
					try {
						const response = await Api.post(`contest/done/${this.criteria.id}`)

						if (response.status === 200) {
							this.contestDone = true
							this.setDone(this.criteria.id, this.criteria.original_id)
						}
					} catch (e) {
						console.log(e)
						errorToast('Ocorreu um <strong>erro</strong> ao finalizar a contestação')
					}
				}
			})
		},
		async sendMessage() {
			if (this.form.message) {
				try {
					this.loadingSend = true
					const response = await Api.post('contest/store', {
						rating_criteria_id: this.id,
						message: this.form.message
					})

					if (response.status === 201) {
						this.form.message = ''
						this.findContestByCriteria()
					}
				} catch (e) {
					console.log(e)
					errorToast('Ocorreu um <strong>erro</strong> ao enviar a contestação')
				} finally {
					this.loadingSend = false
					this.$refs.formChat.reset()
				}
			}
		},
		async removeMessage (contest) {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: 'Atenção',
				message: '<span>Você realmente deseja <br><strong>excluir</strong> esta mesagem?</span> <small>Esse processo não poderá ser desfeito.</small>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: 'Não',
				confirmText: 'Sim',
				onConfirm: async () => {
					try {
						const response = await Api.delete(`contest/destroy/${contest.id}`)

						if (response.status === 200) {
							contest.deleted = true
						}
					} catch (e) {
						console.log(e)
						errorToast('Ocorreu um <strong>erro</strong> ao excluir a mensagem')
					}
				}
			}) 
		},
		async editMessage (contest) {
			Modal.open({
				parent: this,
				component: Message,
				scroll: 'keep',
				customClass: 'is-md',
				trapFocus: true,
				props: { contest: contest, edited: this.editedMessage }
			})
		},
		async viewContestHistory (contest) {
			Modal.open({
				parent: this,
				component: Message,
				scroll: 'keep',
				customClass: 'is-md',
				trapFocus: true,
				props: { contest: contest, historic: true }
			})
		},
		editedMessage() {
			this.findContestByCriteria()
		}
	},
  	mounted() {
		this.setCriteriaContest(null)
		this.isOpening = true
		this.findContestByCriteria()
	}
}
</script>
