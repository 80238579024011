<template>
    <div class="collapse no-events">
        <div class="collapse-trigger">
            <div class="card-header block-button placeholder mb-0">
                <div class="title mb-2">
                    <b-skeleton width="100" height="20"></b-skeleton>
                    <b-skeleton width="150" height="10"></b-skeleton>
                </div>
                <span class="icon">
                    <b-skeleton width="15" height="15"></b-skeleton>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: 'Placeholder'
}
</script>
